import React, { FC, useContext, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { AuthState } from '@/features/common/auth';
import { useOnboardingUseCase } from '@/features/common/onboarding';
import { useRetentionUseCase } from '@/features/retention';
import { useContextualOnboarding } from '@/features/system/contextualOnboarding';
import { Hubspot } from '@/features/system/hubspot';

import { SplashScreen } from '@/components';

import { LocationRestoreContext } from '../hoc';
import { useRedirectAfterLogin } from '../hooks';
import { AuthenticatedLayout } from '../layouts';
import { EXTERNAL_ROUTES } from '../routes';

import { getDefaultRoute } from './getDefaultRoute';

export const AuthenticatedRoutesWrapper: FC = () => {
  const location = useLocation();
  const { reload } = useContextualOnboarding();
  const onboardingUseCase = useOnboardingUseCase();
  const { onRestoreLocation } = useContext(LocationRestoreContext);

  const retentionUseCase = useRetentionUseCase();
  useEffect(() => {
    const subscription = retentionUseCase.handleRetentionOfferAfterLogin().subscribe();
    onboardingUseCase.completeSignupStep(); // for cases where user verifies email while was logged out
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    reload();
  }, [location]);

  useEffect(() => {
    onRestoreLocation();
  }, []);

  return (
    <AuthenticatedLayout>
      <Outlet />
      <Hubspot />
    </AuthenticatedLayout>
  );
};

export const AuthenticatedGuardRoute: React.FC<{
  authState: AuthState;
}> = ({ authState }) => {
  const [redirectAfterLoginPath, setRedirectAfterLogin] = useRedirectAfterLogin();

  switch (authState) {
    case AuthState.Identifying:
      return <SplashScreen />;
    case AuthState.CanAccessSite:
      if (redirectAfterLoginPath) {
        if (Object.values(EXTERNAL_ROUTES).includes(redirectAfterLoginPath)) {
          setRedirectAfterLogin(null);
          window.location.href = redirectAfterLoginPath;
        }

        setRedirectAfterLogin(null);
        return <Navigate to={redirectAfterLoginPath} replace />;
      } else {
        return <Outlet />;
      }
    case AuthState.ShouldSetupAccount:
      return <Navigate to={getDefaultRoute(AuthState.ShouldSetupAccount)} replace />;
    case AuthState.ShouldVerifyEmail:
      return <Navigate to={getDefaultRoute(AuthState.ShouldVerifyEmail)} replace />;
    case AuthState.ShouldProvideCredentials:
      return (
        <Navigate to={getDefaultRoute(AuthState.ShouldProvideCredentials)} replace />
      );
  }
};
